import "./MainTitle.css";
import main_image from "../../images/main_image.png";

const MainTitle = () => {
  return (
    <>
      <h3 className="main_title">
        Fill your job
        <br />
        positions
        <br />
        with the <span>right</span>
        <br />
        people
      </h3>
      <h1 className="sub_title">the quick and easy way</h1>
      <img src={main_image} alt="" className="main_image" />
      <span className="sub_text">
        A platform for businesses that offers a new approach for recruitment
      </span>
    </>
  );
};
export default MainTitle;
