import { useCallback, useEffect, useState } from "react";
import "./App.css";
import EmailSignup from "./components/EmailSignup/EmailSignup";
import Header from "./components/Header/Header";
import MainTitle from "./components/MainTitle/MainTitle";
import Problem from "./components/Problem/Problem";

function App() {
  const [visited, setVisited] = useState(false);

  const handleVisit = useCallback(async () => {
    if (localStorage.getItem("visited") === "true") return;
    localStorage.setItem("visited", "true");
    await fetch("www.quikesy-backend.vercel.app", {
      method: "GET",
    });
    setVisited(true);
  }, []);

  useEffect(() => {
    if (!visited) handleVisit();
  }, [visited, handleVisit]);
  return (
    <>
      <Header />
      <div className="main__container">
        <MainTitle />
        <div className="signup_howItWorks">
          <EmailSignup />
          <button className="how_it_works_button">
            See How it Works
          </button>
        </div>
        <Problem />
      </div>
    </>
  );
}

export default App;
