import "./Problem.css";

const Problem = () => {
  return (
    <div className="problem_container">
      <h4>Receive resumes <br/><span>worth looking at</span></h4>
      <h3>
        Stop wasting time reviewing resumes that do <span>not</span> meet your company job requirements
      </h3>
    </div>
  );
};

export default Problem;
