import { useState } from "react";
import "./EmailSignup.css";

const EmailSignup = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [successText, setSuccessText] = useState("");

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setError("");
    setSuccessText("");
    setLoading(true);
    if (
      !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        email
      )
    ) {
      setError("Email is not valid");
    } else {
      const res = await fetch("www.quikesy-backend.vercel.app/collect", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });
      if (res) {
        const data = await res.json();
        if (data.error) {
          setError(data.error);
        }
        if (data.isAdded) {
          setSuccessText("Successfully signed up");
        }
      }
    }
    setLoading(false);
  };
  return (
    <>
      <form onSubmit={handleSubmit}>
        {error && <span className="email_error">{error}</span>}
        {successText && <span className="email_success">{successText}</span>}
        <input
          type="text"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Your email address..."
        />
        <button type="submit" disabled={isLoading}>
          {isLoading ? "Loading" : "Notify me"}
        </button>
      </form>
      <span className="email_subtext">
        Signup for our waiting list and get an early access
      </span>
    </>
  );
};

export default EmailSignup;
