import logo from "../../images/logo.png";
import './Header.css';

const Header = () => {
  return (
    <nav>
      <img src={logo} alt="Quikesy" />
    </nav>
  );
};

export default Header;
